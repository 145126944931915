// Find the next prime number above a given value
const NextOrEqualOddPrime = (num) => {
   for (let i = num; ; i++) {
      if (!(i & 1))
      {
         continue;
      }
      let isPrime = true;
      for (let d = 2; d * d <= i; d++) {
         if (i % d === 0) {
            isPrime = false;
            break;
         }
      }
      if (isPrime) {
         return i;
      }
   }
};

// Derived from: https://www.geeksforgeeks.org/print-subsets-given-size-set/
// Given an array of values, and a subset length k, return all combinations of size k.
function AllCombinations(arr, k, index = 0, data = new Array(k), i = 0, finalResults = []) {
   // Current combination is ready to be saved
   if (index === k) {
      finalResults.push([...data]);
      return null;
   }

   // When there are no more elements to put in data[]
   if (i >= arr.length) { return null; }

   // Current is included, put next at next location
   data[index] = arr[i]; // eslint-disable-line no-param-reassign
   AllCombinations(
      arr,
      k,
      index + 1,
      data,
      i + 1,
      finalResults,
   );

   // Current is excluded, replace it with next (Note that i+1 is passed, but index is not changed)
   AllCombinations(
      arr,
      k,
      index,
      data,
      i + 1,
      finalResults,
   );

   return finalResults;
}

function ApplyPadding(bytes, lengthResolution) {
   if ((bytes.length > 0) && ((bytes.length % lengthResolution) == 0) && (bytes[bytes.length - 1] == 0)) {
      // If we are already aligned exactly, and we happen to have a zero as our last, byte
      // no need to add any padding. This may be relatively common, so a worthwhile optimization.
      return bytes;
   }
   const extraBytesRequired = lengthResolution - (bytes.length % lengthResolution);
   const extraBytes = new Uint8Array(extraBytesRequired);
   extraBytes.fill(extraBytesRequired);
   return new Uint8Array([...bytes, ...extraBytes]);
}

function GetPaddingLength(bytes, lengthResolution) {
   if (bytes.length == 0) {
      throw new Error('Unable to remove padding from empty array.');
   }
   if ((bytes.length % lengthResolution) != 0) {
      throw new Error(`Invalid sequence provided attempting to remove padding: ${bytes.length}, ${lengthResolution}`);
   }
   const paddingCharCount = bytes[bytes.length - 1];
   if (paddingCharCount > bytes.length) {
      throw new Error('Padding mismatch found.');
   }
   return paddingCharCount;
}

function RemovePadding(bytes, lengthResolution) {
   const paddingCharCount = GetPaddingLength(bytes, lengthResolution);
   return bytes.slice(0, bytes.length - paddingCharCount);
}

module.exports = {
   AllCombinations,
   ApplyPadding,
   GetPaddingLength,
   NextOrEqualOddPrime,
   RemovePadding,
};
