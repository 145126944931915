// Node ALog implementation
class ALog {
   static sLogStream;

   static sSendToStdOut = false;

   static sSendToLog = true;

   static get AnyDebugOut() {
      return this.sSendToLog || this.sSendToStdOut;
   }

   static async DebugWriteLine(message = null) {
      // console.log(message);
      message;
   }
}
module.exports = ALog;
