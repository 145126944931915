<template>
   <div class="modal show" tabindex="-1" role="dialog" id="pricing-modal" aria-modal="true" style="display: block;" data-backdrop="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" id="twitter-modal">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="hidePurchasePopup">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body text-left">

            <div class="row justify-content-md-center pb-row">
              <div class="col-12 col-lg-8 text-center">
                <h2>Pricing Options</h2>
              </div>
              <div class="col-12 col-lg-8 text-center login-signup">
                <ul>
                  <li class="pricing-plan-1 active1" v-if="!isShowingAdvanced"><a @click="showIndividualPlans" href="#" id="individual-btn">Individual</a></li>
                  <li class="pricing-plan-1" v-if="isShowingAdvanced"><a @click="showIndividualPlans" href="#" id="individual-btn">Individual</a></li>

                  <li class="pricing-plan-2 active1" v-if="isShowingAdvanced"><a @click="showAdvancedPlans" href="#" id="corporate-btn">Advanced</a></li>
                  <li class="pricing-plan-2" v-if="!isShowingAdvanced"><a @click="showAdvancedPlans" href="#" id="corporate-btn">Advanced</a></li>

                </ul>
              </div>
            </div>

            <div class="pricing-individual" v-if="!isShowingAdvanced">
              <div class="row justify-content-md-center mt-4 mb-2 pb-row">
                <div class="col-12 col-lg-4 text-center price-box price-1">

                  <h2>3-Slice Credit<sup><i class="bi bi-info-circle" v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="A 3-Slice Credit is required to generate one 3-Slice set"></i></sup></h2>
                  <h3>$9.99</h3>
                  <ul>
                    <li><strong>3-slices</strong><sup><i class="bi bi-info-circle" v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="Slices are the number of QR codes generated in a set. This plan allows you to create sets with up to 3-slices total."></i></sup></li>
                    <li><strong>Unlimited recoveries</strong><sup><i class="bi bi-info-circle" v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="Users can always recover their confidential information by utilizing our free public app, accessible on the Xecret.io website and independently published on GitHub and arweave.org"></i></sup></li>
                  </ul>
                  <a href="/payment?sliceCount=3" class="btn btn-lg 3-slice-btn" data-dismiss="modal">Select</a>
                </div>

                <div class="col-12 col-lg-4 text-center price-box price-2">
                  <h2>4-Slice Credit<sup><i class="bi bi-info-circle" v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="A 4-Slice Credit is required to generate one 4-slice set"></i></sup></h2>
                  <h3>$69</h3>
                  <ul>
                    <li><strong>Up to 4-slices</strong><sup><i class="bi bi-info-circle" v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="Slices are the number of QR codes generated in a set. This plan allows you to create sets with up to 4-slices total."></i></sup></li>
                    <li><strong>Unlimited recoveries</strong><sup><i class="bi bi-info-circle" v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="Users can always recover their confidential information by utilizing our free public app, accessible on the Xecret.io website and independently published on GitHub and arweave.org"></i></sup></li>
                  </ul>
                  <a href="/payment?sliceCount=4" class="btn btn-lg 4-slice-btn" data-dismiss="modal">Select</a>
                </div>

                <div class="col-12 col-lg-4 text-center price-box price-3">

                  <h2>5-Slice Credit<sup><i class="bi bi-info-circle" v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="A 5-Slice Credit is required to generate one 5-slice set"></i></sup></h2>
                  <h3>$99</h3>
                  <ul>
                    <li><strong>Up to 5-slices</strong><sup><i class="bi bi-info-circle" v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="Slices are the number of QR codes generated in a set. This plan allows you to create sets with up to 5-slices total."></i></sup></li>
                    <li><strong>Unlimited recoveries</strong><sup><i class="bi bi-info-circle" v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="Users can always recover their confidential information by utilizing our free public app, accessible on the Xecret.io website and independently published on GitHub and arweave.org"></i></sup></li>
                  </ul>
                  <a href="/payment?sliceCount=5" class="btn btn-lg 5-slice-btn" data-dismiss="modal">Select</a>
                </div>
              </div><!--END ROW-->
            </div><!--END PRICING-INDIVIDUAL-->

            <div class="pricing-corporate" v-if="isShowingAdvanced">
              <div class="row justify-content-md-center mt-4 mb-2 pb-row">
                <div class="col-12 col-lg-4 text-center price-box price-4">
                  
                  <h2>7-Slice Credit<sup><i class="bi bi-info-circle" v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="A 7-Slice Credit is required to generate one 7-slice set"></i></sup></h2>
                  <h3>$499</h3>
                  <ul>
                    <li><strong>Up to 7-slices</strong><sup><i class="bi bi-info-circle" v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="Slices are the number of QR codes generated in a set. This plan allows you to create sets with up to 7-slices total."></i></sup></li>
                    <li><strong>Unlimited recoveries</strong><sup><i class="bi bi-info-circle" v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="Users can always recover their confidential information by utilizing our free public app, accessible on the Xecret.io website and independently published on GitHub and arweave.org"></i></sup></li>
                  </ul>
                  <a href="/payment?sliceCount=7" class="btn btn-lg 7-slice-btn" data-dismiss="modal">Select</a>
                </div>

                <div class="col-12 col-lg-4 text-center price-box price-5">
                  <h2>11-Slice Credit<sup><i class="bi bi-info-circle" v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="An 11-Slice Credit is required to generate one 11-slice set"></i></sup></h2>
                  <h3>$2,499</h3>
                  <ul>
                    <li><strong>Up to 11-slices</strong><sup><i class="bi bi-info-circle" v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="Slices are the number of QR codes generated in a set. This plan allows you to create sets with up to 11-slices total."></i></sup></li>
                    <li><strong>Unlimited recoveries</strong><sup><i class="bi bi-info-circle" v-b-tooltip.hover data-toggle="tooltip" data-placement="top" title="Users can always recover their confidential information by utilizing our free public app, accessible on the Xecret.io website and independently published on GitHub and arweave.org"></i></sup></li>
                  </ul>
                  <a href="/payment?sliceCount=11" class="btn btn-lg 11-slice-btn" data-dismiss="modal">Select</a>
                </div>

                <div class="col-12 col-lg-4 text-center price-box price-6">
                  <h2>API Integration</h2>
                  <h3>&nbsp;</h3>
                  <ul>
                    <li><strong>Contact us for details</strong></li>
                    <li><strong>&nbsp;</strong></li>
                  </ul>
                  <a href="/contact" class="btn btn-lg api-integration-btn" data-dismiss="modal">Select</a>
                </div>
              </div>
            </div><!--END Pricing-corporate-->
          </div>
          <div class="modal-footer">
            <!--<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>-->
          </div>
        </div>
      </div>
   </div>
</template>

<script>

export default {
   name: "PurchasePopup",
   computed: {
   },
   data() {
      return {
         isShowingAdvanced: false
      };
   },
   beforeDestroy() {
   },
   methods: {
      showIndividualPlans() {
         this.isShowingAdvanced = false;
      },
      showAdvancedPlans() {
         this.isShowingAdvanced = true;
      },
      hidePurchasePopup() {
         this.$emit("hidePurchasePopup", "");
      },
   },
   unmounted() {
   },
   async mounted() {
   }
};
</script>

