<template>
   <div class="container">
         <div class="launch-btn">
            <div v-if="isDemo" class="row mb-2 justify-content-center">
               <div class="cold-11 col-lg-6 fadeInDemo pt-3 pl-5 pr-5 m-3" style="margin-top: 20px; color: #333; animation: fadeIn 1.5s; background: #DBDBDB; padding-bottom: -60px !important">
                  <p>When using this application, we recommend the
                  following steps <b>for added security:</b><br/>
                     <ul>
                        <li class="mt-2">Always use a trusted device and network</li>
                        <li class="mt-2">Go offline before entering your confidential data</li>
                        <li class="mt-2 mb-2">Close your browser's tab and clear its cache before coming back online</li>
                     </ul>
                  </p>
               </div>
            </div>
            <div v-if="isDemo" class="row mb-2 justify-content-center" style="margin-top: -25px !important">
               <div style="display: block">
                  <div>
                     <img class="d-none d-lg-inline" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABUCAYAAAA7xZEpAAAABHNCSVQICAgIfAhkiAAAA7RJREFUeF7tXLtuE0EUzdpGsgtooALLkuWHXEALllJABIqULig9UNADX0D4AsIfAH0EHVIEghRIhpYUlh+yZBkqaKCwJfzgjLVCjp313rszu7O27ja24rv3cfaemePMjJ2NEK9ms7kP908NhnhWKpWUz9AuJzTPcCyAzKErgAggywknHSIdIh3CmpSEMkIZoYxQhoWAUEYow2oYmWWEMkIZoQwLAaGMUIbVMDLLCGWEMkIZFgJCGaEMq2FklhHKCGWEMiwEhDJCGVbDyCwjlBHKCGVYCAhlhDKshpFZRigjlBHKsBAQyghlWA2zkrMMkn6IKrOsSunGt2B6k27ua3kMi0++VsENek6r1bo6mUw+w8eF4H7W4s7fjuNsTjf/t9vt26PR6B3+cG4tSmMWgYb4m0wmdwqFwof/pyHQKffwwSumr7UwRyPcLxaLr1Uxp46HhDAIrgJgp87gLJyXaTQaLxViq1CJbo6KEeVy+cGsnwVAYJRCp6jx5I5uwDjfjzrf43TWDuocLgVEfViv189jkFEzz7U4F6WR2zdMIpuVSuXPvA/PI2bdbvfyYDD4CgSvaASO3a3ojO/pdPp6Lpf7cVZyS8/craFGmWoNzCgnXk/K9xCi0ijj8fgIDhKxe9y8hMaJRGJbaY1lt/kCom7GIPsYL8958WNn/QSD6IFfViRAlBPQ5wD8e+TnMI6fgyYvQBP1UH0vMiAAw0GnHML5XV+vMTJA3m/QGXvIe0JJiwyIctbpdNLD4fAj3lYpzmNgU0ulUlv5fH5AzYUFiHLa6/Uu9vv9Gt4WqUEs2bUymUw1m83+4sRnA+IOsgW0Yg1teIkTLCpb5PYTuVVBlTY3ZiBAVBB857mBoIo+GW7QkO37AGQL31G+BIkTGBAVDDPPLoIf4m1cNMoYD2kPM8rbIGCoe7QAcekTJ41C0hrLwNIGxO0U6xqFozVCB8S2RuFqjdABUQEsahS21ogEEEsaJZDWiAyQKDWKjtaIFJCINIqW1ogckJA1irbWsAJIiBpFW2tYA8S0RjGlNawC4mqUI91lDXfZYJv6fw1r0p0S2MCyhueyASU+x8aIdKcEDLqs4bdsQInNsYkMEHc84W698F024BRLsY0UEJUQdevF7BYFSiGmbCIHxO0U360Xs1sUTBVL8WMFEFej7OPV66dFQ/+ZUC9wrAHiSvyFrRdnbVGgPFlTNlYBmd964bVFwVSxFD9WAVEJzmiUDa8tCpRCTNlYB8QdT6bbQrFs0DNVWFA//wBRpEAoaaTUVgAAAABJRU5ErkJggg==" alt="" />
                     <div class="d-none d-lg-inline-block" style="width: 160px !important; display: inline-block;">&nbsp;</div>
                     <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABUCAYAAAA7xZEpAAAABHNCSVQICAgIfAhkiAAAA7RJREFUeF7tXLtuE0EUzdpGsgtooALLkuWHXEALllJABIqULig9UNADX0D4AsIfAH0EHVIEghRIhpYUlh+yZBkqaKCwJfzgjLVCjp313rszu7O27ja24rv3cfaemePMjJ2NEK9ms7kP908NhnhWKpWUz9AuJzTPcCyAzKErgAggywknHSIdIh3CmpSEMkIZoYxQhoWAUEYow2oYmWWEMkIZoQwLAaGMUIbVMDLLCGWEMkIZFgJCGaEMq2FklhHKCGWEMiwEhDJCGVbDyCwjlBHKCGVYCAhlhDKshpFZRigjlBHKsBAQyghlWA2zkrMMkn6IKrOsSunGt2B6k27ua3kMi0++VsENek6r1bo6mUw+w8eF4H7W4s7fjuNsTjf/t9vt26PR6B3+cG4tSmMWgYb4m0wmdwqFwof/pyHQKffwwSumr7UwRyPcLxaLr1Uxp46HhDAIrgJgp87gLJyXaTQaLxViq1CJbo6KEeVy+cGsnwVAYJRCp6jx5I5uwDjfjzrf43TWDuocLgVEfViv189jkFEzz7U4F6WR2zdMIpuVSuXPvA/PI2bdbvfyYDD4CgSvaASO3a3ojO/pdPp6Lpf7cVZyS8/craFGmWoNzCgnXk/K9xCi0ijj8fgIDhKxe9y8hMaJRGJbaY1lt/kCom7GIPsYL8958WNn/QSD6IFfViRAlBPQ5wD8e+TnMI6fgyYvQBP1UH0vMiAAw0GnHML5XV+vMTJA3m/QGXvIe0JJiwyIctbpdNLD4fAj3lYpzmNgU0ulUlv5fH5AzYUFiHLa6/Uu9vv9Gt4WqUEs2bUymUw1m83+4sRnA+IOsgW0Yg1teIkTLCpb5PYTuVVBlTY3ZiBAVBB857mBoIo+GW7QkO37AGQL31G+BIkTGBAVDDPPLoIf4m1cNMoYD2kPM8rbIGCoe7QAcekTJ41C0hrLwNIGxO0U6xqFozVCB8S2RuFqjdABUQEsahS21ogEEEsaJZDWiAyQKDWKjtaIFJCINIqW1ogckJA1irbWsAJIiBpFW2tYA8S0RjGlNawC4mqUI91lDXfZYJv6fw1r0p0S2MCyhueyASU+x8aIdKcEDLqs4bdsQInNsYkMEHc84W698F024BRLsY0UEJUQdevF7BYFSiGmbCIHxO0U360Xs1sUTBVL8WMFEFej7OPV66dFQ/+ZUC9wrAHiSvyFrRdnbVGgPFlTNlYBmd964bVFwVSxFD9WAVEJzmiUDa8tCpRCTNlYB8QdT6bbQrFs0DNVWFA//wBRpEAoaaTUVgAAAABJRU5ErkJggg==" alt="" />
                  </div>

               </div>
            </div>

           <div v-if="isDemo" class="row mb-2 justify-content-center">
             <div class="col-11 col-lg-3 text-center mt-4">
               <div class="create-btn-1">
                 <a :href="demoPrefix + 'create-qr'">
                   <div class="home-bkg">
                     <img :src="$store.state.createBtn1Svg" alt="create button" style="margin-bottom: 13px">
                     <span class="hl-1" style="display: inline-block; line-height: 15px;">CREATE<span style="font-size: small"><br/>QR Codes</span></span>
                   </div>
                 </a>
               </div>
             </div>
             <div class="col-11 col-lg-3 text-center mt-4">
               <div class="create-btn-1">
                 <a :href="demoPrefix + 'recover'">
                   <div class="home-bkg">
                     <img :src="$store.state.retreiveIcon1Svg" alt="retrieve button" style="margin-bottom: 13px">
                     <span class="hl-1" style="display: inline-block; line-height: 15px;">RECOVER<span style="font-size: small"><br/>From QR Codes</span></span>
                   </div>
                 </a>
               </div>
             </div>
           </div><!--END ROW-->

           <div v-else class="row mb-2 justify-content-center">
             <div class="col-11 col-lg-3 text-center mt-4">
               <div class="create-btn-1">
                 <a :href="demoPrefix + 'create-qr'">
                   <div class="home-bkg">
                     <img :src="$store.state.createBtn1Svg" alt="create button">
                     <span class="hl-1">CREATE</span>
                   </div>
                 </a>
               </div>
             </div>
             <div class="col-11 col-lg-3 text-center mt-4">
               <div class="create-btn-1">
                 <a :href="demoPrefix + 'recover'">
                   <div class="home-bkg">
                     <img :src="$store.state.retreiveIcon1Svg" alt="retrieve button">
                     <span class="hl-1">RECOVER</span>
                   </div>
                 </a>
               </div>
             </div>
           </div><!--END ROW-->

         </div>


         <div class="row justify-content-md-center" v-if="!isDemo">
           <div class="col-12 col-lg-6 mt-5">

             <div class="accordion" id="accordionFeatures">
               <div class="card">
                 <div class="card-header" id="headingOne" @click="toggleCouponCodeView">
                   <h5 class="mb-0 text-center mt-1">
                     <button class="btn btn-link coming-soon" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                       <h5><span class="dd-title">Promo Code</span> <span :class="{'dd-caret': true, 'active-1': isShowingCouponCode}"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                         <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path></svg></span>
                       </h5>
                     </button>
                   </h5>
                 </div>
                 <div id="collapseOne" class="show" aria-labelledby="headingOne" data-parent="#accordionFeatures" style="" v-if="isShowingCouponCode">
                   <div class="card-body">
                     <div class="news-bkg">
                        <p><b>{{shareUrl}}</b>&nbsp;&nbsp;
                           <a @click="copyLinkToClipboard"><i class="bi bi-clipboard p-2"></i></a>
                           <a target="_blank" :href="twitterShareUrl"><i class="bi bi-twitter p-2"></i></a>
                           <a target="_blank" :href="linkedInShareUrl"><i class="bi bi-linkedin p-2"></i></a>
                        </p>
                        <p>
                           Use this Promo Code to get $5 off your first order.
                        </p>
                        <div class="blue p-3" v-if="isShowingCopiedFeedback">
                           Copied!
                        </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div><!--END Accordian-->

           </div><!--END COL-12-->
         </div><!--END ROW-->

         <div class="row justify-content-md-center">
           <div class="col-12 col-lg-6 mt-4">
             <div class="accordion" id="accordionNews">
               <div class="card">
                 <div class="card-header" id="headingOne" @click="toggleNewsletterSignupView">
                   <h5 class="mb-0 text-center mt-1">
                     <button class="btn btn-link signup-1" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                       <h5><span class="dd-title">Signup for our newsletter</span> <span :class="{'dd-caret': true, 'active-1': isShowingNewsletterSignup}"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                         <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path></svg></span>
                       </h5>
                     </button>
                   </h5>
                 </div>
                 <div id="collapseTwo" class="" aria-labelledby="headingOne" data-parent="#accordionNews" v-if="isShowingNewsletterSignup">
                   <div class="card-body">
                     <EmailSignup />
                   </div>
                 </div>
               </div>
             </div><!--END Accordian-->
           </div>
         </div>

         <div class="row justify-content-md-center">
           <div class="col-12 col-lg-6 mt-4 text-center" style="margin-bottom:100px">
             <p class="patent-1">Patent Pending: 17/395,661</p>
           </div>
         </div>
  
   </div>
</template>

<script>
import EmailSignup from "@/components/email-signup"

export default  {
   data() {
      return {
         slug: "5OFF",
         isShowingCouponCode: false,
         isShowingNewsletterSignup: false,
         isShowingCopiedFeedback: false,
      };
   },
   computed: {
      twitterShareUrl: function() {
         return `https://twitter.com/intent/tweet?text=${encodeURIComponent(this.shareUrl)}`;
      },
      linkedInShareUrl: function() {
         return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(this.shareUrl)}`;
      },
      shareUrl: function() {
         return "https://" + window.location.host + "/?r=" + this.slug;
      },
      isValidSlug: function() {
         return this.slugErrorMessage !== "";
      },
      slugErrorMessage: function() {
         if (!/^[-a-zA-Z0-9_]+$/.test(this.slug)) {
            return "A referral code must consist of letters, numbers, hyphen, and underscore only.";
         }
         return "";
      },
      isDemo() {
         return location.href.includes("/demo");
      },
      demoPrefix() {
         return this.isDemo ? "/demo/" : "/";
      }
   },
   components: {
      EmailSignup,
   },
   mounted() {
      this.$store.commit("setActiveCreditId", 0);

      // Save referral slug for payment
      const referralSlug = new URLSearchParams(window.location.search).get('r');

      if (referralSlug !== null) {
         console.log(`provided referral slug: ${referralSlug}`);
         this.$store.commit("setReferralSlug", referralSlug)
      }

      window.gtag("event", "HomePage");
   },
   methods: {
      copyLinkToClipboard() {
         navigator.clipboard.writeText(this.shareUrl);
         this.isShowingCopiedFeedback = true;
         setTimeout(() => this.isShowingCopiedFeedback = false, 2000);
      },
      toggleCouponCodeView() {
         this.isShowingCouponCode = !this.isShowingCouponCode;
      },
      toggleNewsletterSignupView() {
         this.isShowingNewsletterSignup = !this.isShowingNewsletterSignup;
      }
   }
}
</script>

