import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

let vuexLocal = new VuexPersistence(); 

export default new Vuex.Store({
   state: {
      accountNumber: 0,
      activeCreditId: "",
      activeInvoiceId: "",
      validateCreditId: "",
      createShareCount: 1,
      createShareThreshold: 1,
      didPromptPurchaseOptions: false,
      promptPurchaseOptions: false,
      email: "",
      isCreateQrTourRun: false,
      isHidingNavigateAway: false,
      isWallet: false,
      isReferralAdmin: false,
      isSiteAdmin: false,
      loggedIn: false,
      magicNumber: 123456789,
      needsEmailConfirm: false,
      pageTemplateSvgText: "",
      username: "",
      usernameShort: "",
      referralSlug: "",
      shares: [],
      shareName: "",
      shareUUID: new Uint8Array(4),
      verifiedShareUUIDs: [],
      accountData: {},
      didValidateAllShares: false,
      offlineIconSvg: "",
      printerBlueSvg: "",
      printerGraySvg: "",
      createBtn1Svg: "",
      createSvg: "",
      retreiveIconSvg: "",
      retreiveIcon1Svg: "",
      helpIconSvg: "",
      isOffline: false,
      purchaseSliceCount: 0,
      purchaseSliceCountTime: 0,
      printScope: "full"  // "full" or "individual"
   },
   mutations: {
      setIsCreateQrTourRun(state, isCreateQrTourRun) {
         state.isCreateQrTourRun = isCreateQrTourRun;
      },
      setIsOffline(state, isOffline) {
         state.isOffline = isOffline;
      },
      setDidValidateAllShares(state, didValidateAllShares) {
         state.didValidateAllShares = didValidateAllShares;
      },
      setShareCount(state, shareCount) {
         state.createShareCount = shareCount;
      },
      setShareThreshold(state, shareThreshold) {
         state.createShareThreshold = shareThreshold;
      },
      setShares(state, shares) {
         state.shares = shares;
      },
      clearShares(state) {
         state.shares = [];
      },
      setPrintScope(state, printScope) {
         state.printScope = printScope;
      },
      setName(state, name) {
         state.shareName = name;
      },
      setUUID(state) {
         state.shareUUID = new Uint8Array(4);
         crypto.getRandomValues(state.shareUUID);
      },
      setOfflineIconSvg(state, val) {
         state.offlineIconSvg = val;
      },
      setRetreiveIconSvg(state, val) {
         state.retreiveIconSvg = val;
      },
      setRetreiveIcon1Svg(state, val) {
         state.retreiveIcon1Svg = val;
      },
      setHelpIconSvg(state, val) {
         state.helpIconSvg = val;
      },
      setPrinterBlueSvg(state, val) {
         state.printerBlueSvg = val;
      },
      setPrinterGraySvg(state, val) {
         state.printerGraySvg = val;
      },
      setCreateBtn1Svg(state, val) {
         state.createBtn1Svg = val;
      },
      setCreateSvg(state, val) {
         state.createSvg = val;
      },
      login(state) {
         state.loggedIn = true;
         console.log("saving logged in state");
      },
      logout(state) {
         state.loggedIn = false;
         state.username = "";
         state.usernameShort = "";
         state.needsEmailConfirm = false;
         state.isWallet = false;
         state.isSiteAdmin = false;
         state.isReferralAdmin = false;
         state.activeCreditId = "";
         state.activeInvoiceId = "";
         state.accountData = {};

         console.log("logging out");
      },
      setPromptPurchaseOptions(state, promptPurchaseOptions) {
         state.promptPurchaseOptions = promptPurchaseOptions;
      },
      setDidPromptPurchaseOptions(state, didPromptPurchaseOptions) {
         state.didPromptPurchaseOptions = didPromptPurchaseOptions;
      },
      setNeedsEmailConfirm(state, needsEmailConfirm) {
         state.needsEmailConfirm = needsEmailConfirm;
      },
      setAccountData(state, accountData) {
         state.accountData = accountData;
         state.promptPurchaseOptions = accountData.prompt_purchase_options;
         state.email = accountData.username;
         state.isReferralAdmin = accountData.is_referral_admin;
         state.isSiteAdmin = accountData.is_site_admin;
         state.isWallet = accountData.is_wallet;
         state.needsEmailConfirm = accountData.needs_email_confirm;
         state.loggedIn = accountData.username;
      },
      setReferralSlug(state, slug) {
         state.referralSlug = slug;
      },
      setPurchaseSliceCount(state, sliceCount) {
         state.purchaseSliceCount = parseInt(sliceCount);
      },
      setPurchaseSliceCountTime(state, timeStamp) {
         state.purchaseSliceCountTime = timeStamp;
      },
      setIsReferralAdmin(state, isReferralAdmin) {
         state.isReferralAdmin = isReferralAdmin;
      },
      setIsSiteAdmin(state, isSiteAdmin) {
         state.isSiteAdmin = isSiteAdmin;
      },
      setIsWallet(state, isWallet) {
         state.isWallet = isWallet;
      },
      approveShareUUID(state, UUID) {
         state.verifiedShareUUIDs.push(UUID);
         console.log(state.verifiedShareUUIDs);
      },
      setActiveInvoiceId(state, invoiceId) {
         state.activeInvoiceId = invoiceId;
         console.log(state.activeInvoiceId);
      },
      setPageTemplate(state, pageTemplateSvgText) {
          state.pageTemplateSvgText = pageTemplateSvgText;
      },
      setActiveCreditId(state, activeCreditId) {
         state.activeCreditId = activeCreditId;
         console.log(activeCreditId);
      },
      setValidateCreditId(state, validateCreditId) {
         state.validateCreditId = validateCreditId;
      },
      setEmail(state, email) {
         state.email = email;
      },
      setIsHidingNavigateAway(state, isHidingNavigateAway)
      {
         state.isHidingNavigateAway = isHidingNavigateAway;
      }

   },
   actions: {
   },
   modules: {
   },
   plugins: [vuexLocal.plugin]
})
