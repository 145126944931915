import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import axios from'axios'
import VueAxios from 'vue-axios'
import VueTour from 'vue-tour'

import {
   AlertPlugin,
   ButtonPlugin,
   ModalPlugin,
   TooltipPlugin,
   FormPlugin,
   FormGroupPlugin,
   FormInputPlugin,
   ListGroupPlugin,
} from 'bootstrap-vue'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@/assets/css/bootstrap.min.scss'
import '@/assets/css/custom2.scss'
import '@/assets/css/index.scss'

require('vue-tour/dist/vue-tour.css')

import qrscan from './components/qr-scan.vue'

Vue.use(AlertPlugin);
Vue.use(ButtonPlugin);
Vue.use(ModalPlugin);
Vue.use(TooltipPlugin);
Vue.use(FormPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(ListGroupPlugin);
Vue.use(VueTour);

Vue.use(VueAxios, axios);

Vue.component('qrscan', qrscan);

Vue.config.productionTip = false

new Vue({
   router,
   store,
   render: h => h(App)
}).$mount('#app')

// csrf setup token 
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';
