const ALog = require('./log');

class ABitMatrix {
   fRows = [];
   
   get Cols() { return this.fRows[0].length; }
   
   get Rows() { return this.fRows.length; }
   
   constructor(cols, rows) {
      for (let i = 0; i < rows; ++i) {
         this.fRows.push(new Array(cols).fill(false));
      }
   }
   
   PutVal(colIdx, rowIdx, val) {
      this.fRows[rowIdx][colIdx] = val;
   }
   
   GetVal(colIdx, rowIdx) {
      return this.fRows[rowIdx][colIdx];
   }
   
   /// Applies in place on the first array the result of XOR-ing both arrays
   ApplyXor(applyToArray, fromOtherArray) { // eslint-disable-line class-methods-use-this
      for (let i = 0; i < applyToArray.length; ++i) {
         applyToArray[i] ^= fromOtherArray[i]; // eslint-disable-line no-param-reassign
      }
   }

   DebugPrint() {
      if (ALog.AnyDebugOut) {
         for (let rowIdx = 0; rowIdx < this.Rows; ++rowIdx) {
            ALog.DebugWriteLine(`${(`000${rowIdx}`).slice(-4)}. ${this.fRows[rowIdx].map((b) => (b ? '1' : '0')).join('')}`);
         }
         ALog.DebugWriteLine('......0123456789abcdef0123456789abcdef0123456789abcdef');
      }
   }
   
   DeleteRows(startIdx, deleteCount) {
      this.fRows.splice(startIdx, deleteCount);
   }
   
   Crop(startCol, cols, startRow, rows) {
      this.fRows = this.fRows.slice(startRow, startRow + rows).map(
         (arr) => arr.slice(startCol, startCol + cols),
         );
         ALog.DebugWriteLine('After crop:');
         this.DebugPrint();
      }
   }
   
   module.exports = ABitMatrix;
   