const { ApplyPadding, NextOrEqualOddPrime, RemovePadding } = require('./utils');
const ARecoveryShare = require('./recoveryshare');
const ARecoveryMatrix = require('./recoverymatrix');
const ASecureRandom = require('./securerandom');
const ALog = require('./log');

class AXorIda {
   fPrimeNum;

   fThresholdCount;

   fNumShares;

   fLengthResolution;

   constructor(k, n, minLengthResolution) {
      this.fThresholdCount = k;
      this.fNumShares = n;
      this.fPrimeNum = NextOrEqualOddPrime(n);
      this.fLengthResolution = minLengthResolution + AXorIda.GetRequiredPadding(n, minLengthResolution);
   }

   ValidateShareIndexes(shareIndexes) {
      if (!shareIndexes.every((s) => s >= 0 && s < this.fNumShares)) {
         throw new Error('Share indexes must be >= 0 and < the number of shares.');
      }
      if (new Set(shareIndexes).size !== shareIndexes.length) {
         throw new Error('Share indexes may not contain duplicates.');
      }
      if (shareIndexes.length !== this.fThresholdCount) {
         throw new Error('Provided share indexes must equal threshold count.');
      }
   }

   static GetRequiredPadding(numShares, byteLenWithoutPadding) {
      const blockSize = NextOrEqualOddPrime(numShares) - 1;
      let result = blockSize - (byteLenWithoutPadding % blockSize);
      if (result === blockSize) {
         result = 0;
      }
      return result;
   }

   // IDA function to split a secret message into multiple encrypted pieces that may be XOR'd
   // together to reassemble the original. There are n distributed shares, and k required shares for
   // reassembly.
   SplitSecret(secret) {
      secret = ApplyPadding(secret, this.fLengthResolution);

      const result = [this.fNumShares];
      const blockSize = this.fPrimeNum - 1;
      const numBlocks = secret.length / blockSize;

      // In order to split a secret, each piece must have an equal length (equal to the prime num -1),
      // any padding is expected to be done prior to running this logic.
      if (AXorIda.GetRequiredPadding(this.fNumShares, secret.length) !== 0) {
         throw new Error('Secret improperly padded.');
      }

      // Generate k source arrays to act as the matrix columns in the final step. Each column contains
      // prime-1 blocks.
      const sourceArrays = [this.fThresholdCount];
      for (let i = 0; i < sourceArrays.length; i++) {
         sourceArrays[i] = new Uint8Array(secret.length);
      }
      // The first k-1 columns are filled with random bits.
      for (let i = 0; i < this.fThresholdCount - 1; ++i) {
         sourceArrays[i] = new Uint8Array(secret.length);
         ASecureRandom.NextBytes(sourceArrays[i]);
      }
      // Initialize the final column to the correct size.
      sourceArrays[this.fThresholdCount - 1] = secret;

      if (ALog.AnyDebugOut) {
         for (let sourceArrayIdx = 0; sourceArrayIdx < this.fThresholdCount; ++sourceArrayIdx) {
            ALog.DebugWriteLine(`SplitSecret source array ${sourceArrayIdx}: ${sourceArrays[sourceArrayIdx].join(',')}`);
         }
      }

      // Construct each share of the secret
      for (let shareIdx = 0; shareIdx < this.fNumShares; ++shareIdx) {
         result[shareIdx] = new ARecoveryShare(shareIdx, new Uint8Array(secret.length));
         // For each block of the secret
         for (let blockIdx = 0; blockIdx < numBlocks; ++blockIdx) {
            const blockOffset = blockIdx * blockSize;
            for (let destBlockRowIdx = 0; destBlockRowIdx < blockSize; ++destBlockRowIdx) {
               for (let srcColIdx = 0; srcColIdx < sourceArrays.length; ++srcColIdx) {
                  let srcBlockRowIdx = (destBlockRowIdx + shareIdx * srcColIdx) % (blockSize + 1);
                  if (srcBlockRowIdx != blockSize) {
                     srcBlockRowIdx = this.OffsetIndex(srcBlockRowIdx, blockSize);
                     result[shareIdx].SliceBytes[blockOffset + destBlockRowIdx] ^=
                      sourceArrays[srcColIdx][blockOffset + srcBlockRowIdx];
                  }
               }
            }
         }
      }
      return result;
   }

   GenerateRecoveryMatrix(_shareIndexes) {
      const shareIndexes = _shareIndexes.sort();
      this.ValidateShareIndexes(shareIndexes);
      return ARecoveryMatrix.BuildRecoveryMatrix(
       this.fPrimeNum, 
       this.fThresholdCount, 
       this.fNumShares, 
       shareIndexes);
   }

   OffsetIndex(num, count) {
      return (count + Math.trunc(count / 2) + ((num & 1) * (count - ((count + 1) & 1) - num - 1)) + (((num + 1) & 1) * num)) % count;
   }

   RecoverSecret(recoveryMatrix, _availableShares) {
      recoveryMatrix.DebugPrint();
      ALog.DebugWriteLine(`RecoverSecret k=${this.fThresholdCount}, n=${this.fNumShares}, prime=${this.fPrimeNum}`);

      const np = this.fPrimeNum;

      // ensure shares are ordered by share index (ascending)
      const availableShares = _availableShares.sort((a, b) => ((a.ShareIdx > b.ShareIdx) ? 1 : -1));

      // be strict about share composition
      this.ValidateShareIndexes(availableShares.map((s) => s.ShareIdx));

      // be strict about share slice byte length
      availableShares.forEach((share) => {
         if ((share.SliceBytes.length % (np - 1)) !== 0) {
            throw new Error('Unexpected: share slice bytes is not evenly divisible by block size');
         }
      });

      if (ALog.AnyDebugOut) {
         availableShares.forEach((share) => {
            ALog.DebugWriteLine(`RecoverSecret share: ${share.SliceBytes.join(',')}`);
         });
      }

      // calculate how many blocks we have to recover
      const firstShare = availableShares[0];
      const numBlocks = firstShare.SliceBytes.length / recoveryMatrix.Rows;

      const result = new Uint8Array(numBlocks * recoveryMatrix.Rows);
      for (let blockIdx = 0; blockIdx < numBlocks; ++blockIdx) {
         const blockOffset = blockIdx * recoveryMatrix.Rows;
         for (let r = 0; r < recoveryMatrix.Rows; ++r) {
            const destByteIdx = blockOffset + this.OffsetIndex(r, recoveryMatrix.Rows);
            for (let c = 0; c < recoveryMatrix.Cols; ++c) {
               if (recoveryMatrix.GetVal(c, r)) {
                  const shareIdx = Math.trunc(c / (np - 1));
                  const shareOffset = c % (np - 1);
                  result[destByteIdx] ^= availableShares[shareIdx].SliceBytes[blockOffset + shareOffset];
               }
            }
         }
      }
      return RemovePadding(result, this.fLengthResolution);
   }
}

module.exports = AXorIda;
