<template>
   <div id="app" class="d-none" v-cloak>

      <div class="overlay2" id="loginBlueOverlay">
        <div class="logo-load">
          <img src="@/assets/img/xecret-logo-light.png" alt="Xecret.io">
        </div>
      </div>

      <div class="dropdown-background" @click="dismissDropdown()" v-if="isDropDownShown" v-cloak />

      <div class="container-fluid" v-if="!isDemo && $route.meta.showSignUp" v-cloak>
         <div class="row" id="header-1">
            <div class="container">
               <div class="col-12">
                  <img src="@/assets/img/xecret-logo-dark.png" alt="Xecret.io logo" width="115">
                  <div class="hdr-btn-1"><a href="signup">Sign Up</a></div>
               </div>
            </div>
         </div>
      </div>
      <div class="container-fluid" v-if="!isDemo && (!$route.meta.showSignUp && !$store.state.accountData.account_number) && !($store.state.loggedIn && !$route.meta.showSignUp)" v-cloak>
         <div class="row" id="header-1">
            <div class="container">
               <div class="col-12">
                  <img src="@/assets/img/xecret-logo-dark.png" alt="Xecret.io logo" width="115">
                  <div class="hdr-btn-1"><a href="login">Log In</a></div>
               </div>
            </div>
         </div>
      </div>

      <div class="container-fluid" v-if="!isDemo && $store.state.loggedIn && !$route.meta.showSignUp" v-cloak>
         <nav class="navbar  navbar-light bg-light" id="header-1">
            <div class="container">
               <a class="navbar-brand mr-auto" href="/" v-if="!$store.state.isHidingNavigateAway"><img src="@/assets/img/xecret-logo-dark.png" alt="Xecret.io logo"></a>
               <img src="@/assets/img/xecret-logo-dark.png" alt="Xecret.io logo" v-if="$store.state.isHidingNavigateAway">

               <button @click="toggleDropDown()" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" v-if="$store.state.loggedIn">
                  <span class="navbar-toggler-icon"></span>
               </button>

               <div @click="dismissDropdown()" class="navbar-collapse justify-content-end collapse show" id="navbarNav" style="" v-if="isDropDownShown">
                  <ul class="navbar-nav">
                     <li class="nav-item" v-if="!$store.state.isHidingNavigateAway">
                       <a class="nav-link" href="account" v-if="!$store.state.needsEmailConfirm">Account</a>
                     </li>
                     <li class="nav-item" v-if="!$store.state.isHidingNavigateAway">
                       <a class="nav-link" href="payment" v-if="!$store.state.needsEmailConfirm" @click="$event.preventDefault(); showPurchasePageWithPopup()">Purchase Credits</a>
                     </li>
                     <li class="nav-item" v-if="!$store.state.isHidingNavigateAway && !$store.state.needsEmailConfirm && $store.state.accountData && $store.state.accountData.offer_headline">
                       <a class="nav-link" style="color: #99cc33 !important; font-weight: 600;" href="payment" @click="$event.preventDefault(); navigateToOffer()">{{$store.state.accountData.offer_headline.split(" - ")[0]}}</a>
                     </li>
                     <li class="nav-item" v-if="!$store.state.isHidingNavigateAway">
                       <a class="nav-link" href="messages">Messages</a>
                     </li>
                     <li class="nav-item" v-if="isReferralProgramActive && !$store.state.isHidingNavigateAway">
                       <a class="nav-link" :href="referralProgramUrl">Referral Program</a>
                     </li>
                     <li class="nav-item" v-if="$store.state.accountData && $store.state.accountData.is_site_admin && !$store.state.isHidingNavigateAway">
                       <a class="nav-link" style="color: blue !important" href="template-admin">Template Admin</a>
                     </li>
                     <li class="nav-item" v-if="$store.state.accountData && $store.state.accountData.is_referral_admin && !$store.state.isHidingNavigateAway">
                       <a class="nav-link" style="color: blue !important" href="referral-admin">Referral Payable Admin</a>
                     </li>
                     <li class="nav-item" v-if="$store.state.accountData && $store.state.accountData.is_site_admin && !$store.state.isHidingNavigateAway">
                       <a class="nav-link" style="color: blue !important" href="support-admin">Support Admin</a>
                     </li>
                     <li class="nav-item" v-if="$store.state.accountData && $store.state.accountData.is_site_admin && !$store.state.isHidingNavigateAway">
                       <a class="nav-link" style="color: blue !important" href="coupon-admin">Coupon Admin</a>
                     </li>
                     <li class="nav-item nav-last">
                       <a class="nav-link" href="login" @click="$event.preventDefault(); logout()">Logout</a>
                     </li>
                  </ul>
               </div>

            </div>
         </nav>
      </div>

      <div class="container-fluid desktop-hide" v-if="isDemo" v-cloak>
         <nav class="navbar navbar-light bg-light" id="header-1">
            <div class="container justify-content-center">
               <a href="/demo"><img src="@/assets/img/xecret-logo-dark.png" alt="Xecret.io logo"></a>
            </div>
            <div class="container justify-content-center">
               <div><a class="link-upgrade-green" href="/demo" @click="$event.preventDefault(); showDemoPurchasePageWithPopup();">Upgrade</a></div>&nbsp;<span class="link-logout-gray">|</span>&nbsp;
               <div><a class="link-logout-gray" href="https://xecret.io">Logout</a></div>

            </div>
         </nav>
      </div>
      <div class="container-fluid mobile-hide" v-if="isDemo" v-cloak>
         <nav class="navbar navbar-light bg-light" id="header-1">
            <div class="container">
               <a class="navbar-brand mr-auto" href="/demo"><img src="@/assets/img/xecret-logo-dark.png" alt="Xecret.io logo"></a>
               
               <div class="btn btn-upgrade-green hdr-btn-1"><a class="btn-upgrade-green" href="/demo" @click="$event.preventDefault(); showDemoPurchasePageWithPopup();">Upgrade</a></div>&nbsp;

               <div class="btn btn-outline-secondary"><a href="https://xecret.io">Logout</a></div>
            </div>
         </nav>
      </div>

      <div class="container-fluid" id="navigate-warning-banner" v-if="!isDemo && $store.state.isHidingNavigateAway && !$route.query.offer && ($route.path === '/print-qr' || $route.path === '/print-final2' || $route.path === '/create-qr')" v-cloak>
         <div class="row">
           <div class="col-12">
             <div class="warning-banner-text-hide" style="display: block !important">
               <div>
                 <p style="margin: 0px">Warning: Please stay on this page and complete the process of creating your QR code set.
                 If you navigate away from this page before the process is complete, the usage credit will still be consumed.</p>
               </div>
             </div>
           </div>
         </div>
      </div>

      <div class="container-fluid" id="upgrade-banner" v-if="$store.state.accountData && $store.state.accountData.offer_headline && !$route.query.offer && $route.path === '/'" v-cloak>
         <div class="row">
           <div class="col-12">
             <a @click="toggleOfferExpand" v-if="!isOfferExpanded" href="#" id="upgrade-expand" style="">{{$store.state.accountData.offer_headline}}</a>
             <div class="upgrade-banner-text-hide mt-3" style="display: block !important" v-if="isOfferExpanded">
               <div class="mb-5 mt-4">
                 <p>{{$store.state.accountData.offer_message_line1}}</p>
                 <p>{{$store.state.accountData.offer_message_line2}}</p>
                 <p v-if="$store.state.accountData.offer_message_line3">{{$store.state.accountData.offer_message_line3}}</p>
               </div>

               <div class="mt-4 mb-5">
                 <a @click="navigateToOffer" href="payment?offer=1" class="upg-btn upgrade-btn-2">Upgrade</a><a @click="toggleOfferExpand" href="#" class="upg-btn upgrade-cancel-bnt">Cancel</a>
               </div>
             </div>
           </div>
         </div>
      </div>

      <div class="container-fluid dk-bkg-1" v-if="$route.meta.title !== ''" v-cloak>
         <div class="container">
            <div class="row justify-content-md-center" style="margin-left: -10px;">
               <div class="bc-title pt-3 pb-3">
                  <p><strong> &nbsp; {{ $route.meta.title }} </strong></p>
               </div>
            </div>
         </div>
      </div>

      <div id="nav" v-if="$route.meta.showNav && !$store.state.isHidingNavigateAway" v-cloak>
         <footer class="pb-0 pt-3 fixed-bottom">
            <div class="container">
               <div class="row text-center justify-content-md-center foot-menu">
                  <router-link :to="demoPrefix + 'create-qr'" class="col-4 col-lg-3">
                     <img :src="$store.state.createSvg" alt="create icon" />
                     <p>Create</p>
                  </router-link>
                  <router-link :to="demoPrefix + 'recover'" class="col-4 col-lg-3">
                     <img :src="$store.state.retreiveIconSvg" alt="Retrieve icon" />
                     <p>Recover</p>
                  </router-link>
                  <a href="https://xecret.io/help/index.html?d=0" class="col-4 col-lg-3" v-if="!isDemo">
                     <img :src="$store.state.helpIconSvg" alt="Help icon" />
                     <p>Help</p>
                  </a>
                  <a href="https://xecret.io/help/index.html?d=1" class="col-4 col-lg-3" v-else>
                     <img :src="$store.state.helpIconSvg" alt="Help icon" />
                     <p>Help</p>
                  </a>
               </div>
            </div>
         </footer>
      </div>
      <router-view v-cloak/>
      <div id="sizeExtender" style="height: 150px" />

      <PurchasePopup 
         v-if="showPurchasePopup && !isDemo"
         @hidePurchasePopup="hidePurchasePopup()"
         >
      </PurchasePopup>

      <div id="printPage" />
 
   </div>


</template>

<script>

import offlineIconSvg from "@/assets/img/offline-icon.svg";
import printerBlueSvg from "@/assets/img/printer_blue.svg";
import printerGraySvg from "@/assets/img/printer_gray.svg";
import createBtn1Svg from "@/assets/img/create-btn-1.svg";
import createSvg from "@/assets/img/create.svg";
import retreiveIconSvg from "@/assets/img/retreive-icon.svg";
import retreiveIcon1Svg from "@/assets/img/retreive-icon-1.svg";
import helpIconSvg from "@/assets/img/help-icon.svg";

import PurchasePopup from "@/components/purchase-popup"

export default {
   components: {
      PurchasePopup,
   },
   data() {
      return {
         isDropDownShown: false,
         isOfferExpanded: false,
         isOkToShowUi: false,
         showPurchasePopup: false,
      }
   },
   computed: {
      isReferralProgramActive() {
         var accountData = this.$store.state.accountData;
         if (accountData && accountData.frontend_settings && accountData.frontend_settings.referral_toggle) {
            return accountData.frontend_settings.referral_toggle.value === "true";
         }
         else {
            return true;
         }
      },
      referralProgramUrl() {
         var accountData = this.$store.state.accountData;
         if (accountData && accountData.frontend_settings && accountData.frontend_settings.referral_redirect_url && 
               accountData.frontend_settings.referral_redirect_url.value) {
            return accountData.frontend_settings.referral_redirect_url.value;
         }
         else {
            return "/referral";
         }
      },
      isDemo() {
         return location.href.includes("/demo");
      },
      demoPrefix() {
         return this.isDemo ? "/demo/" : "/";
      }
   },
   beforeMount() {
      if (!this.$store.state.printScope || (this.$store.state.printScope !== "full" && this.$store.state.printScope !== "individual")) {
         this.$store.commit("setPrintScope", "full");
      }
   },
   mounted() {
      let that = this;

      let forceLoadSettings = this.$route.query.invalidateCache === '1'

      if (this.isDemo && (forceLoadSettings || !this.$store.state.accountData || !this.$store.state.accountData.frontend_settings)) {
         this.axios
            .get("/api/demo-get-frontend-settings", {}, { withCredentials: true })
            .then((response) => {
               this.$store.commit('setAccountData', { frontend_settings: response.data.frontend_settings });
            })
            .catch((error) => {
               console.log(error);
            });
         
      }

      // attempt re-read after two hours
      let oldestSessionBeforeReRead = new Date(new Date().getTime() - (2 * 60 * 60 * 1000));

      if (this.$store.state.loggedIn && (forceLoadSettings || !this.$store.state.accountData || 
       (new Date(this.$store.state.accountData.updated_date)) < oldestSessionBeforeReRead)) {
         this.axios
            .get("/api/account-data", { withCredentials: true })
            .then((response) => {
               that.$store.commit("setAccountData", response.data);

               if (that.$store.state.accountData.prompt_purchase_options && !that.$store.state.didPromptPurchaseOptions &&
                window.location.pathname === "/") {
                  that.showPurchasePopup = true;
                  that.$store.commit("setDidPromptPurchaseOptions", true);
                  console.log("showing purchase popup");
               }
            })
            .catch((error) => {
               console.error(error);
               that.logout();
            });
      } 

      if (this.$store.state.promptPurchaseOptions && !this.$store.state.didPromptPurchaseOptions &&
       window.location.pathname === "/") {
         this.showPurchasePopup = true;
         this.$store.commit("setDidPromptPurchaseOptions", true);
      }

      if (location.href.includes("/login")) {
         console.log("we are on the login page");
         document.getElementById("loginBlueOverlay").classList.remove("d-none");
         document.getElementById("app").classList.remove("d-none");
         setTimeout(() => { 
            document.getElementById("loginBlueOverlay").classList.add("d-none");
         }, 1500);

         that.isOkToShowUi = true; 
      }
      else {
         document.getElementById("loginBlueOverlay").classList.add("d-none");
         document.getElementById("app").classList.remove("d-none");
         this.isOkToShowUi = true;
      }

      window.addEventListener('online', () => that.$store.commit("setIsOffline", false));
      window.addEventListener('offline', () => that.$store.commit("setIsOffline", true));

      let svgNames = [
         [offlineIconSvg, "offlineIconSvg"],
         [printerBlueSvg, "printerBlueSvg"],
         [printerGraySvg, "printerGraySvg"],
         [createBtn1Svg, "createBtn1Svg"],
         [retreiveIconSvg, "retreiveIconSvg"],
         [retreiveIcon1Svg, "retreiveIcon1Svg"],
         [helpIconSvg, "helpIconSvg"],
         [createSvg, "createSvg"]]
         ;

      this.$store.commit("setOfflineIconSvg", offlineIconSvg);
      this.$store.commit("setPrinterBlueSvg", printerBlueSvg);
      this.$store.commit("setPrinterGraySvg", printerGraySvg);
      this.$store.commit("setCreateBtn1Svg", createBtn1Svg);
      this.$store.commit("setCreateSvg", createSvg);
      this.$store.commit("setRetreiveIconSvg", retreiveIconSvg);
      this.$store.commit("setRetreiveIcon1Svg", retreiveIcon1Svg);
      this.$store.commit("setHelpIconSvg", helpIconSvg);

      for (let i = 0; i < svgNames.length; ++i) {
         let importedName = svgNames[i][0];
         let key = svgNames[i][1];
         if (!this.$store.state[key] || this.$store.state[key].startsWith("/static")) {
            fetch(importedName)
               .then(res => res.text())
               .then(svgText =>  {
                  this.$store.commit("set" + key.substr(0, 1).toUpperCase() + key.substr(1), 
                  'data:image/svg+xml;utf8,' + encodeURIComponent(svgText.replaceAll('"', "'")));
               });
         }
      }

      if (window.location.href.startsWith("http://")) {
         window.location.assign("https://" + window.location.host);
      }
   },
   methods: {
      toggleOfferExpand() {
         this.isOfferExpanded = !this.isOfferExpanded;
      },
      navigateToOffer() {
         window.location.assign("payment?offer=1");
      },
      logout() {
         this.axios
            .post("/api/logout", {}, { withCredentials: true })
            .then((response) => {
               console.log(response);
               this.$store.commit('logout');
            })
            .catch((error) => {
               console.log(error);
               window.location.assign("login");
            });

         this.$router.push({name: "Login"});
      },
      toggleDropDown() {
         this.isDropDownShown = !this.isDropDownShown;
         console.log("toggle dropdown");
      },
      dismissDropdown() {
         this.isDropDownShown = false;
      },
      hidePurchasePopup() {
         this.showPurchasePopup = false;
      },
      showPurchasePageWithPopup() {
         this.dismissDropdown();
         this.showPurchasePopup = true;
      },
      showDemoPurchasePageWithPopup() {
         this.dismissDropdown();
         window.location.assign("https://xecret.io#pricing");
      },
   }
};
</script>


