const ABitMatrix = require('./bitmatrix');
const ALog = require('./log');

class ARecoveryMatrix {

   static BuildRecoveryMatrix(primeNum, thresholdCount, numShares, shareIndexes) {
      const np = primeNum;
      let blockSize = np - 1;
      const G_I = new ABitMatrix(
         thresholdCount * blockSize + thresholdCount * blockSize,
         numShares * blockSize,
      );
      for (let shareIdx = 0; shareIdx < numShares; ++shareIdx) {
         for (let shareRowIdx = 0; shareRowIdx < blockSize; ++shareRowIdx) {
            const destRow = shareIdx * blockSize + shareRowIdx;
            for (let sourceColIdx = 0; sourceColIdx < thresholdCount; ++sourceColIdx) {
               let sourceRowIdx = (shareRowIdx + shareIdx * sourceColIdx) % np;
               if (sourceRowIdx < blockSize)
               {
                  let destCol = sourceColIdx * blockSize + sourceRowIdx;
                  G_I.PutVal(destCol, destRow, true);
               }
            }
         }
      }

      ALog.DebugWriteLine('Before matrix manipulation:');
      G_I.DebugPrint();

      // delete any shares not provided
      for (let i = numShares - 1; i >= 0; --i) {
         if (!shareIndexes.some((s) => s === i)) {
            G_I.DeleteRows(i * (np - 1), np - 1);
         }
      }

      // set up identity matrix to the right of G_I
      for (let r = 0; r < G_I.Rows; ++r) {
         G_I.PutVal(thresholdCount * blockSize + r, r, true);
      }

      ALog.DebugWriteLine('Identity matrix added:');
      G_I.DebugPrint();

      // table 4 line 7
      ARecoveryMatrix.PerformForwardElimination(G_I);

      // extract sub portion as [G0 J0] per table 4 line 8 and continuing
      const cropToHeight = np - 1;
      const cropToWidth = (thresholdCount * blockSize) + cropToHeight;
      G_I.Crop(G_I.Cols - cropToWidth, cropToWidth, G_I.Rows - cropToHeight, cropToHeight);
      const I_M = G_I;
      ARecoveryMatrix.PerformBackwardSubstitution(I_M);
      const M = I_M;
      const startColIdx = M.Rows;

      M.Crop(startColIdx, M.Cols - startColIdx, M.Rows - (np - 1), (np - 1));

      return M;
   }

   static PerformForwardElimination(bitMatrix) {
      let pivotCol = 0;
      let rowIdx = 0;
      for (;;) {
         let foundAny = false;
         for (let r = rowIdx; r < bitMatrix.Rows; ++r) {
            if (bitMatrix.fRows[r][pivotCol]) {
               foundAny = true;
               if (r !== rowIdx) {
                  const buf = bitMatrix.fRows[r];
                  bitMatrix.fRows[r] = bitMatrix.fRows[rowIdx];
                  bitMatrix.fRows[rowIdx] = buf;
               }
               break;
            }
         }
         if (foundAny) {
            // zero out all of pivotCol below bitMatrix row
            for (let r = rowIdx + 1; r < bitMatrix.Rows; ++r) {
               if (bitMatrix.fRows[r][pivotCol]) {
                  bitMatrix.ApplyXor(bitMatrix.fRows[r], bitMatrix.fRows[rowIdx]);
               }
            }
            pivotCol += 1;
            rowIdx += 1;
         } else {
            pivotCol += 1;
         }
         
         ALog.DebugWriteLine('\n\n\nForward elimination stage:');
         bitMatrix.DebugPrint();
         
         if (rowIdx === bitMatrix.Rows) {
            break;
         }
      }
   }
      
   static PerformBackwardSubstitution(bitMatrix) {
      const lastRow = bitMatrix.fRows[bitMatrix.fRows.length - 1];
      let lastCol = 0;
      while (!lastRow[lastCol]) {
         lastCol += 1;
      }
      for (let r = bitMatrix.Rows - 2; r >= 0; --r) {
         const endZeros = bitMatrix.Rows - r - 1;
         for (let c = lastCol; c > lastCol - endZeros; --c) {
            if (bitMatrix.fRows[r][c]) {
               bitMatrix.ApplyXor(bitMatrix.fRows[r], bitMatrix.fRows[bitMatrix.Rows - (lastCol - c) - 1]);
               ALog.DebugWriteLine('\n\n\nBackward substitution stage:');
               bitMatrix.DebugPrint();
            }
         }
      }
   }
}

module.exports = ARecoveryMatrix;
