<template>
   <div class="news-bkg">
      <form class="mt-3">
         <div class="row">
            <div class="col-12">
               <input type="email" class="form-control form-control-lg" id="newsletter1" aria-describedby="" placeholder="Email address" v-model="emailText">
            </div>

            <div class="col-12 mt-4 mb-3">
               <button
                  type="submit"
                  class="btn btn-outline-primary btn-lg btn-block"
                  @click="$event.preventDefault(); submitEmail();"
                  v-if="emailText !== ''"
               >
                  <i class="bi bi-newspaper" style="padding-right:20px"></i>
                  Sign up
               </button>
                <button
                  type="submit"
                  class="btn btn-secondary btn-lg btn-block"
                  @click="$event.preventDefault();"
                  v-else
               >
                  <i class="bi bi-newspaper" style="padding-right:20px"></i>
                  Sign up
               </button>

            </div>
         </div>
      </form>

      <b-alert
         variant="danger"
         dismissible
         :show="errorMessage !== ''"
         @dismissed="errorMessage = ''"
      >
         {{ errorMessage }}
      </b-alert>
      <b-alert
         variant="success"
         dismissible
         :show="infoMessage !== ''"
         @dismissed="infoMessage = ''"
      >
         {{ infoMessage }}
      </b-alert>
   </div>
</template>

<script>
export default {
   name: "EmailSignup",
   data() {
      return {
         emailText: "",
         errorMessage: "",
         infoMessage: "",
         dismissTime: 2000
      }
   },
   methods: {
      submitEmail() {
         const email = this.emailText;
         // clear previous data
         this.errorMessage = "";
         this.infoMessage = "";
         this.emailText = "";

         // post the provided email adress
         fetch("https://1aflbpyznd.execute-api.us-east-1.amazonaws.com/prod/subscribers", {
            method: "post",
            headers: {
               'Content-Type': 'application/json'
            },
            body: JSON.stringify({"email": email})
         }).then(res => {
            console.log(res);
            const context = this;
            // process response
            if (res.status >= 400) {
               this.errorMessage = "Invalid email.";
               setTimeout(() => context.errorMessage = "", this.dismissTime);
            }
            else if (res.status === 200){
               this.infoMessage = "Success!";
               setTimeout(() => context.infoMessage = "", this.dismissTime);
               return res.json();
            }
         }).catch(error => {
            console.error(error);
            this.errorMessage = "Invalid email.";
         });
      },
   }
};
</script>