import axios from "axios";
import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views/index.vue'
import store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [
   {
      path: '/',
      name: 'index',
      meta: {
         title: '',
         showNav: true,
         showMenu: true,
         requiresAuth: true,
      },
      component: index
   },
   {
      path: '/demo',
      name: 'demo',
      meta: {
         title: '',
         showNav: true,
         showMenu: true,
         requiresAuth: false,
      },
      component: index
   },
   {
      path: '/contact',
      name: 'contact',
      linkActiveClass: 'active',
      meta: {
         title: '',
         showNav: true,
         showMenu: true,
         requiresAuth: true,
      },
      component: () => import('../views/contact.vue')
   },
   {
      path: '/create-qr',
      name: 'create-qr',
      meta: {
         title: 'Create QR Codes',
         showNav: true,
         showMenu: true,
         requiresAuth: true,
      },
      linkActiveClass: 'active',
      component: () => import(/* webpackChunkName: "group-offline" */ '../views/create-qr.vue')
   },
   {
      path: '/demo/create-qr',
      name: 'demo-create-qr',
      meta: {
         title: 'Create QR Codes',
         showNav: true,
         showMenu: true,
         requiresAuth: false,
      },
      linkActiveClass: 'active',
      component: () => import(/* webpackChunkName: "group-offline" */ '../views/create-qr.vue')
   },
   {
      path: '/recover',
      name: 'recover',
      meta: {
         title: 'Recover Confidential Information',
         showNav: true,
         showMenu: true,
         requiresAuth: false,
      },
      linkActiveClass: 'active',
      component: () => import(/* webpackChunkName: "group-offline" */ '../views/recover.vue')
   },
   {
      path: '/demo/recover',
      name: 'demo-recover',
      meta: {
         title: 'Recover Confidential Information',
         showNav: true,
         showMenu: true,
         requiresAuth: false,
      },
      linkActiveClass: 'active',
      component: () => import(/* webpackChunkName: "group-offline" */ '../views/recover.vue')
   },
   {
      path: '/demo2/recover',
      name: 'demo2-recover',
      meta: {
         title: 'Recover Confidential Information',
         showNav: true,
         showMenu: true,
         requiresAuth: false,
      },
      linkActiveClass: 'active',
      component: () => import(/* webpackChunkName: "group-offline" */ '../views/recover.vue')
   },
   {
      path: '/print-qr',
      name: 'print-qr',
      meta: {
         title: 'Create QR Codes > Print QR Code',
         showNav: false,
         showMenu: true,
         requiresAuth: true,
      },
      component: () => import(/* webpackChunkName: "group-offline" */ '../views/print-qr.vue'),
      beforeEnter: (_to, _from, next) => {
         if (_to.path !== '/print-qr' && _to.path !== '/print-final2' && _to.path !== '/create-qr' && _to.path !== '/validate') {
            store.commit("setIsHidingNavigateAway", false);
         }
         // Redirect to create-qr when we have no shares
         if (store.state.shares.length === 0) {
            next({name: "create-qr", replace: true});
         }
         else {
            next();
         }
      }
   },
   {
      path: '/demo/print-qr',
      name: 'demo-print-qr',
      meta: {
         title: 'Create QR Codes > Print QR Code',
         showNav: false,
         showMenu: true,
         requiresAuth: false,
      },
      component: () => import(/* webpackChunkName: "group-offline" */ '../views/print-qr.vue'),
      beforeEnter: (_to, _from, next) => {
         if (_to.path !== '/print-qr' && _to.path !== '/print-final2' && _to.path !== '/create-qr' && _to.path !== '/validate') {
            store.commit("setIsHidingNavigateAway", false);
         }
         // Redirect to create-qr when we have no shares
         if (store.state.shares.length === 0) {
            next({name: "create-qr", replace: true});
         }
         else {
            next();
         }
      }
   },
   {
      path: '/validate',
      name: 'validate',
      meta: {
         title: 'Create QR Codes > Validate',
         showNav: false,
         showMenu: true,
         requiresAuth: true,
      },
      component: () => import(/* webpackChunkName: "group-offline" */ '../views/validate.vue')
   },
   {
      path: '/demo/validate',
      name: 'demo-validate',
      meta: {
         title: 'Create QR Codes > Validate',
         showNav: false,
         showMenu: true,
         requiresAuth: false,
      },
      component: () => import(/* webpackChunkName: "group-offline" */ '../views/validate.vue')
   },
   {
      path: '/validateset',
      name: 'validateset',
      meta: {
         title: 'Validate a Xecret',
         showNav: true,
         showMenu: true,
         requiresAuth: false,
      },
      component: () => import(/* webpackChunkName: "group-offline" */ '../views/validateset.vue')
   },
   {
      path: '/demo/validateset',
      name: 'demo-validateset',
      meta: {
         title: 'Validate a Xecret',
         showNav: true,
         showMenu: true,
         requiresAuth: false,
      },
      component: () => import(/* webpackChunkName: "group-offline" */ '../views/validateset.vue')
   },
   {
      path: '/print-final2',
      name: 'print-final2',
      meta: {
         title: '',
         showNav: true,
         showMenu: true,
         requiresAuth: true,
      },
      component: () => import(/* webpackChunkName: "group-offline" */ '../views/print-final2.vue')
   },
   {
      path: '/demo/print-final2',
      name: 'demo-print-final2',
      meta: {
         title: '',
         showNav: true,
         showMenu: true,
         requiresAuth: false,
      },
      component: () => import(/* webpackChunkName: "group-offline" */ '../views/print-final2.vue')
   },
   {
      path: '/signup',
      name: 'Signup',
      meta: {
         title: 'Create Account',
         showNav: false,
         showMenu: false,
         requiresAuth: false,
      },
      component: () => import('../views/sign-up.vue')
   },
   {
      path: '/payment',
      name: 'Payment',
      meta: {
         title: '',
         showNav: false,
         showMenu: true,
         requiresAuth: true,
      },
      component: () => import('../views/payment.vue')
   },
   {
      path: '/account',
      name: 'Account',
      meta: {
         title: 'Account',
         showNav: true,
         showMenu: true,
         requiresAuth: true,
      },
      component: () => import('../views/account.vue')
   },
   {
      path: '/messages',
      name: 'Messages',
      meta: {
         title: 'Messages',
         showNav: true,
         showMenu: true,
         requiresAuth: true,
      },
      component: () => import('../views/messages.vue')
   },
   {
      path: '/restore-credit',
      name: 'Restore Credit',
      meta: {
         title: 'Restore Credit',
         showNav: true,
         showMenu: true,
         requiresAuth: true,
      },
      component: () => import('../views/restore-credit.vue')
   },
   {
      path: '/referral',
      name: 'Referral',
      meta: {
         title: 'Referral Program',
         showNav: true,
         showMenu: true,
         requiresAuth: true,
      },
      component: () => import('../views/referral.vue')
   },
   {
      path: '/referral-admin',
      name: 'Referral Payable Admin',
      meta: {
         title: 'Referral Payable Admin',
         showNav: true,
         showMenu: true,
         requiresAuth: true,
      },
      component: () => import('../views/referral-admin.vue'),
      beforeEnter: (_to, from, next) => {
         // Redirect to create-qr when we have no shares
         if (!store.state.isReferralAdmin) {
            next({name: from.name, replace: true});
         }
         else {
            next();
         }
      }
   },
   {
      path: '/template-admin',
      name: 'Template Admin',
      meta: {
         title: 'Template Admin',
         showNav: true,
         showMenu: true,
         requiresAuth: true,
      },
      component: () => import('../views/template-admin.vue'),
      beforeEnter: (_to, from, next) => {
         // Redirect to create-qr when we have no shares
         if (!store.state.isSiteAdmin) {
            next({name: from.name, replace: true});
         }
         else {
            next();
         }
      }
   },
   {
      path: '/account-admin',
      name: 'AccountAdmin',
      meta: {
         title: 'Account Admin',
         showNav: true,
         showMenu: true,
         requiresAuth: true,
      },
      component: () => import('../views/account-admin.vue'),
      beforeEnter: (_to, from, next) => {
         // Redirect to create-qr when we have no shares
         if (!store.state.isSiteAdmin) {
            next({name: from.name, replace: true});
         }
         else {
            next();
         }
      }
   },
   {
      path: '/support-admin',
      name: 'Support Admin',
      meta: {
         title: 'Support Admin',
         showNav: true,
         showMenu: true,
         requiresAuth: true,
      },
      component: () => import('../views/support-admin.vue'),
      beforeEnter: (_to, from, next) => {
         // Redirect to create-qr when we have no shares
         if (!store.state.isSiteAdmin) {
            next({name: from.name, replace: true});
         }
         else {
            next();
         }
      }
   },
   {
      path: '/coupon-admin',
      name: 'Coupon Admin',
      meta: {
         title: 'Coupon Admin',
         showNav: true,
         showMenu: true,
         requiresAuth: true,
      },
      component: () => import('../views/coupon-admin.vue'),
      beforeEnter: (_to, from, next) => {
         // Redirect to create-qr when we have no shares
         if (!store.state.isSiteAdmin) {
            next({name: from.name, replace: true});
         }
         else {
            next();
         }
      }
   },
   {
      path: '/login',
      name: 'Login',
      meta: {
         title: '',
         showNav: false,
         showMenu: false,
         showSignUp: true,
         requiresAuth: false,
      },
      component: () => import('../views/login.vue')
   },
   {
      path: '/confirm',
      name: 'Confirm Email',
      meta: {
         title: '',
         showNav: false,
         showMenu: true,
         showSignUp: false,
         requiresAuth: false,
      },
      component: () => import('../views/confirm.vue')
   },
   {
      path: '/reset-password',
      name: 'Reset Password',
      meta: {
         title: 'Reset Password',
         showNav: false,
         showMenu: false,
         requiresAuth: false,
      },
      component: () => import('../views/reset-password.vue')
   },
   {
      path: '/error404',
      name: 'error-404',
      meta: {
         title: 'Page Not Found',
         showNav: true,
         showMenu: true,
         requiresAuth: false,
      },
      linkActiveClass: 'active',
      component: () => import(/* webpackChunkName: "group-offline" */ '../views/error404.vue')
   },
]

const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL,
   routes
})

// Enforce auth requirements
router.beforeEach((to, from, next) => {
   console.log(to);

   if (!to.name)
   {
      console.log("Routing to 404");
      next({name: 'error-404', query: {  }, path: '/error404'})
   }


   if (to.query.r) {
      store.commit("setReferralSlug", to.query.r);
      next({name: "Signup"});
   }
   if (to.query.sliceCount) {
      store.commit("setPurchaseSliceCount", to.query.sliceCount);
      store.commit("setPurchaseSliceCountTime", (new Date()).getTime());
      if (!store.state.loggedIn) {
         next({name: "Signup"});
      }
      else {
         next({name: "Payment"});
      }
      return;
   }
   else if (to.query.sl) {
      store.commit("setPurchaseSliceCount", to.query.sl);
      store.commit("setPurchaseSliceCountTime", (new Date()).getTime());
      if (!store.state.loggedIn) {
         next({name: "Signup"});
      }
      else {
         next({name: "Payment"});
      }
      return;
   }

   store.commit("setIsHidingNavigateAway", false);
   if (to.meta.requiresAuth && !store.state.loggedIn) {
      axios
         .get("/api/account-data", { withCredentials: true })
         .then((response) => {
            console.log(response.data);

            store.commit("setAccountData", response.data);

            store.commit("login");

            window.gtag("event", "LoginSuccess");

            next();
         })
         .catch((error) => {
            if (to.query.r) {
               store.commit("setReferralSlug", to.query.r);
               next({name: "Signup"});
            }
            else {
               next({name: "Login"});
            }

            console.error(error)
         });

   } 
   else if (to.path !== '/confirm' && to.meta.requiresAuth && !store.state.accountData.is_crypto && store.state.needsEmailConfirm) {
      next({name: "Confirm Email"})
   } 
   else {
      next();
   }
});

export default router
