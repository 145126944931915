class ARecoveryShare {
   ShareIdx;

   SliceBytes;

   constructor(idx, bytes) {
      this.ShareIdx = idx;
      this.SliceBytes = bytes;
   }
}

module.exports = ARecoveryShare;
