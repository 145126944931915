// The number of bytes in the header must align to a multiple of:
const HEADER_BYTE_ALIGNMENT = 32;

// Pack header metadata and payload into a flat Uint8Array
export function pack({ magicNumber, n, k, shareId, UUID, payload }) {
   // console.log({ magicNumber: magicNumber, n: n, k: k, shareId: shareId, uuid: uuid, payload: payload });

   // expected bytes in header 2 * 4 bytes (magic number and uuid), 3 * 1 bytes (n, k, shareId)
   const rawHeaderLength = 4 + 4 + 3;

   // The header needs to be aligned to a 32 byte boundary
   const paddedHeaderLength = rawHeaderLength % HEADER_BYTE_ALIGNMENT === 0 ?
      rawHeaderLength :
      rawHeaderLength + (HEADER_BYTE_ALIGNMENT - (rawHeaderLength % HEADER_BYTE_ALIGNMENT));

   const payloadLength = payload.byteLength === undefined ? Object.keys(payload).length : payload.byteLength;

   let buffer = new Uint8Array(paddedHeaderLength + payloadLength);
   let index = 0;
   
   // n, k, shareId -- 1 byte each
   buffer[index++] = n;
   buffer[index++] = k;
   buffer[index++] = shareId;

   // UUID -- 4 bytes
   buffer[index++] = UUID[0];
   buffer[index++] = UUID[1];
   buffer[index++] = UUID[2];
   buffer[index++] = UUID[3];

   // zero out the following bytes to satisfy the header byte alignment
   for (let i = 0; i < index % HEADER_BYTE_ALIGNMENT; i++) {
      buffer[index++] = 0;
   }

   // magic number -- last 4 bytes of the header
   index -= 4;
   buffer[index++] = ((magicNumber & 0x000000ff) >> 0);
   buffer[index++] = ((magicNumber & 0x0000ff00) >> 8);
   buffer[index++] = ((magicNumber & 0x00ff0000) >> 16);
   buffer[index++] = ((magicNumber & 0xff000000) >> 24);

   // Copy the payload into the buffer
   for (let i = 0; i < payloadLength; i++) {
      buffer[index++] = (payload[i]);
   }

   return buffer;
}

// Unpack the header metadata and payload from a flat Uint8Array
export function unpack(buffer) {
   let index = 0;
   
   // n, k, shareId -- 1 byte each
   const _n = buffer[index++];
   const _k = buffer[index++];
   const _shareId = buffer[index++];

   // UUID -- 4 bytes
   const _UUID = new Uint8Array(4);
   _UUID[0] = buffer[index++];
   _UUID[1] = buffer[index++];
   _UUID[2] = buffer[index++];
   _UUID[3] = buffer[index++];
   
   // Skip to the end of the header (aligned to 32 bytes)
   if (index % HEADER_BYTE_ALIGNMENT !== 0) {
      index += HEADER_BYTE_ALIGNMENT - (index % HEADER_BYTE_ALIGNMENT);
   }

   // magic number -- last 4 bytes of the header
   index -= 4;
   const _magicNumber =
      (buffer[index++] << 0 ) |
      (buffer[index++] << 8 ) |
      (buffer[index++] << 16) |
      (buffer[index++] << 24);

   // the remaining bytes are the payload buffer
   const _payload = new Uint8Array(buffer.slice(index))

   return {
      magicNumber: _magicNumber,
      n: _n,
      k: _k,
      shareId: _shareId,
      UUID: _UUID,
      payload: _payload
   };
}